var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.canViewVerticalNavMenuGroup(_vm.item) ? _c('li', {
    staticClass: "dropdown dropdown-submenu",
    class: {
      'show': _vm.isOpen,
      'disabled': _vm.item.disabled,
      'sidebar-group-active active open': _vm.isActive,
      'openLeft': _vm.openChildDropdownOnLeft
    },
    on: {
      "mouseenter": function () {
        return _vm.updateGroupOpen(true);
      },
      "mouseleave": function () {
        return _vm.updateGroupOpen(false);
      }
    }
  }, [_c('b-link', {
    staticClass: "dropdown-item",
    class: {
      'dropdown-toggle': _vm.item.children
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function () {
        return _vm.updateGroupOpen(!_vm.isOpen);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": _vm.item.icon || 'CircleIcon',
      "size": "24"
    }
  }), _c('span', {
    staticClass: "menu-title"
  }, [_vm._v(_vm._s(_vm.t(_vm.item.title)))])], 1), _c('ul', {
    ref: "refChildDropdown",
    staticClass: "dropdown-menu"
  }, _vm._l(_vm.item.children, function (child) {
    return _c(_vm.resolveNavItemComponent(child), {
      key: child.header || child.title,
      ref: "groupChild",
      refInFor: true,
      tag: "component",
      attrs: {
        "item": child
      }
    });
  }), 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }